<template>
    <v-container>
        <p class="font-size-14 grey-a--text text-right mb-8 mb-md-12"><span style="color: rgb(255, 0, 0)">*</span> 필수입력입니다.</p>
        <div class="table-style table-style--default">
            <myinfo-modify-row label="내용" pointer>
                <v-textarea v-model="board.content" hide-details outlined dense auto-grow />
            </myinfo-modify-row>
            <myinfo-modify-row label="첨부파일">
                <div class="mb-10 mb-md-16">
                    <v-radio-group v-model="board.attachmentType" row hide-details>
                        <v-radio value="사진" label="사진" />
                        <v-radio value="영상" label="영상" />
                    </v-radio-group>
                </div>
                <template v-if="board.attachmentType == '사진'">
                    <v-file-input v-model="board.files" v-bind="fileInputAttrs" />
                </template>
                <template v-if="board.attachmentType == '영상'">
                    <v-text-field v-model="board.youtube" hide-details outlined dense label="YouTube 주소" persistent-placeholder placeholder="https://youtu.be/videoId" />
                </template>
            </myinfo-modify-row>
            <myinfo-modify-row label="태그입력" pointer>
                <v-combobox v-model="board.tags" placeholder="Tab을 누르면 항목이 추가됩니다" multiple hide-details clearable :append-icon="null" outlined dense>
                    <template v-slot:selection="{ attrs, item, parent }">
                        <v-chip v-bind="attrs" small outlined class="ml-0 mr-8">
                            <div class="d-flex align-center">
                                <span class="pr-4"># {{ item }}</span>
                                <v-icon color="grey-a" small @click="parent.selectItem(item)"> $delete </v-icon>
                            </div>
                        </v-chip>
                    </template>
                </v-combobox>
            </myinfo-modify-row>
        </div>

        <div class="mt-40 mt-md-70">
            <div class="v-btn--group">
                <v-btn @click="save" x-large rounded color="primary" class="w-100 mw-180px">확인</v-btn>
            </div>
        </div>
    </v-container>
</template>

<script>
import MyinfoModifyRow from "@/components/client/mypage/myinfo-modify-row.vue";
export default {
    components: {
        MyinfoModifyRow,
    },
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data() {
        return {
            board: this.initBoard(),
        };
    },
    computed: {
        fileInputAttrs() {
            const attrs = {
                multiple: true,
                outlined: true,
                clearable: true,
                "small-chips": true,
                "hide-details": true,
            };
            if (this.board.attachmentType == "사진") {
                return {
                    ...attrs,
                    accept: "image/*",
                    placeholder: "권장사이즈 800*400 (3:4), 최대 20장",
                };
            }
            if (this.board.attachmentType == "영상") {
                return {
                    ...attrs,
                    accept: "video/mp4,video/x-m4v,video/*",
                    placeholder: "권장사이즈 100mb 미만, 최대 20개",
                };
            }

            return undefined;
        },
        isLoggedIn() {
            return !!this.$store.state.payload?._user;
        },
    },
    mounted() {
        this.reroute();
    },
    watch: {
        value() {
            this.board = this.initBoard();
        },
        "board.attachmentType"() {
            this.board.files = [];
        },
    },
    methods: {
        initBoard() {
            return {
                ...this.value,
                tags: this.value?.tags || [],
                files: this.value?.files || [],
                attachmentType: this.value?.attachmentType || "사진",
            };
        },
        async emit() {
            this.$emit("input", this.board);
        },
        save() {
            this.emit().then(() => this.$emit("save"));
        },
        reroute() {
            if (!this.isLoggedIn) {
                alert("회원만 작성할 수 있습니다.");
                this.$router.go(-1);
            }
        },
    },
};
</script>
