var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('p', {
    staticClass: "font-size-14 grey-a--text text-right mb-8 mb-md-12"
  }, [_c('span', {
    staticStyle: {
      "color": "rgb(255, 0, 0)"
    }
  }, [_vm._v("*")]), _vm._v(" 필수입력입니다.")]), _c('div', {
    staticClass: "table-style table-style--default"
  }, [_c('myinfo-modify-row', {
    attrs: {
      "label": "내용",
      "pointer": ""
    }
  }, [_c('v-textarea', {
    attrs: {
      "hide-details": "",
      "outlined": "",
      "dense": "",
      "auto-grow": ""
    },
    model: {
      value: _vm.board.content,
      callback: function ($$v) {
        _vm.$set(_vm.board, "content", $$v);
      },
      expression: "board.content"
    }
  })], 1), _c('myinfo-modify-row', {
    attrs: {
      "label": "첨부파일"
    }
  }, [_c('div', {
    staticClass: "mb-10 mb-md-16"
  }, [_c('v-radio-group', {
    attrs: {
      "row": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.attachmentType,
      callback: function ($$v) {
        _vm.$set(_vm.board, "attachmentType", $$v);
      },
      expression: "board.attachmentType"
    }
  }, [_c('v-radio', {
    attrs: {
      "value": "사진",
      "label": "사진"
    }
  }), _c('v-radio', {
    attrs: {
      "value": "영상",
      "label": "영상"
    }
  })], 1)], 1), _vm.board.attachmentType == '사진' ? [_c('v-file-input', _vm._b({
    model: {
      value: _vm.board.files,
      callback: function ($$v) {
        _vm.$set(_vm.board, "files", $$v);
      },
      expression: "board.files"
    }
  }, 'v-file-input', _vm.fileInputAttrs, false))] : _vm._e(), _vm.board.attachmentType == '영상' ? [_c('v-text-field', {
    attrs: {
      "hide-details": "",
      "outlined": "",
      "dense": "",
      "label": "YouTube 주소",
      "persistent-placeholder": "",
      "placeholder": "https://youtu.be/videoId"
    },
    model: {
      value: _vm.board.youtube,
      callback: function ($$v) {
        _vm.$set(_vm.board, "youtube", $$v);
      },
      expression: "board.youtube"
    }
  })] : _vm._e()], 2), _c('myinfo-modify-row', {
    attrs: {
      "label": "태그입력",
      "pointer": ""
    }
  }, [_c('v-combobox', {
    attrs: {
      "placeholder": "Tab을 누르면 항목이 추가됩니다",
      "multiple": "",
      "hide-details": "",
      "clearable": "",
      "append-icon": null,
      "outlined": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          item = _ref.item,
          parent = _ref.parent;
        return [_c('v-chip', _vm._b({
          staticClass: "ml-0 mr-8",
          attrs: {
            "small": "",
            "outlined": ""
          }
        }, 'v-chip', attrs, false), [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c('span', {
          staticClass: "pr-4"
        }, [_vm._v("# " + _vm._s(item))]), _c('v-icon', {
          attrs: {
            "color": "grey-a",
            "small": ""
          },
          on: {
            "click": function ($event) {
              return parent.selectItem(item);
            }
          }
        }, [_vm._v(" $delete ")])], 1)])];
      }
    }]),
    model: {
      value: _vm.board.tags,
      callback: function ($$v) {
        _vm.$set(_vm.board, "tags", $$v);
      },
      expression: "board.tags"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-40 mt-md-70"
  }, [_c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    staticClass: "w-100 mw-180px",
    attrs: {
      "x-large": "",
      "rounded": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("확인")])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }